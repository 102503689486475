/* eslint-disable no-undef */
import React from 'react';
import './Home.css';
import { Link } from 'react-router-dom';
import {
    Button,
    Layout, Menu, message, Popconfirm,
} from 'antd';
import 'antd/dist/antd.css';
import {
    BookOutlined,
    UserOutlined,
    VideoCameraOutlined,
    QuestionCircleOutlined,
    LogoutOutlined,
    SwapOutlined,
   AliwangwangOutlined,
   CalendarOutlined,
   TeamOutlined,
  } from '@ant-design/icons';
import PropTypes from 'prop-types';

function Home(props) {
    const removeUserData = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('userDetails');
        window.location = '/login';
    };
    const ContentFunction = props.content.type;
    const { Sider } = Layout;
    return (
        <Layout>
            <Sider
                style={{
                    overflow: 'auto',
                    height: '100vh',
                    position: 'fixed',
                    left: 0,
                    background: 'tomato',
                }}
            >
                <div className="navbarLogo" style={{ background: 'tomato', textDecoration: 'none' }} >
                    <Link to='/'>
                        <div style={{ fontSize: 25, color: 'white', textDecoration: 'none' }}>LetsEndorse </div>
                    </Link>
                </div>
                <Menu mode="vertical" defaultSelectedKeys={['4']} style={{ background: 'tomato', color: 'white' }}>
                    <Menu.Item key="1" icon={<QuestionCircleOutlined />}>
                        <Link to="/questions" />
                        Question Bank
                    </Menu.Item>
                    <Menu.Item key="2" icon={<BookOutlined />}>
                        <Link to="/course" />
                        Courses
                    </Menu.Item>
                    <Menu.Item key="3" icon={<VideoCameraOutlined />}>
                        <Link to="/library" />
                        Video Library
                    </Menu.Item>
                    <Menu.Item key="4" icon={<VideoCameraOutlined />}>
                        <Link to="/resources" />
                        Resources
                    </Menu.Item>
                    <Menu.Item icon={<UserOutlined />}>
                        <Link to="/users" />
                        Users
                    </Menu.Item>
                    <Menu.Item icon={<UserOutlined />}>
                        <Link to="/admins" />
                        Admins
                    </Menu.Item>
                    <Menu.Item icon={<SwapOutlined />}>
                        <Link to="/synonyms" />
                        Synonyms
                    </Menu.Item>
                    <Menu.Item icon={<AliwangwangOutlined/>}>
                      <Link to ="/quotes" />
                      Quotes
                    </Menu.Item>
                    <Menu.Item icon={<CalendarOutlined/>}>
                      <Link to ="/session"/>
                      Sessions
                    </Menu.Item>
                    <Menu.Item icon={<TeamOutlined />}>
                      <Link to ="/mentors"/>
                      Mentors
                    </Menu.Item>
                </Menu>
                <div className='logout-btn-container '>
                    <Button color='#feb'>
                        <Popconfirm title="Are you sure ?" onConfirm={() => { removeUserData(); message.success('Log out successful!'); }} onCancel={() => { message.info('Good choice!'); }} okText="Yes" cancelText="No">
                            Logout <LogoutOutlined />
                        </Popconfirm></Button></div>
            </Sider>
            <Layout
                className="site-layout"
                style={{
                    marginLeft: 200,
                    overflow: 'auto',
                }}
            >
                <ContentFunction history={props.history} />
            </Layout>
        </Layout>
    );
}

Home.propTypes = {
    content: PropTypes.object,
};
export default Home;
